<template>
  <div class="block" id="online-examples">
    <div class="block__intro">
      <h2>Online examples</h2>
    </div>

    <div class="block__content">
      <h3 id="items-attributes">It makes me really happy to see online sites that are using the component:</h3>
      <ul>
        <li><a href="https://partner.fairtradecertified.org/profile/aprocassi-cooperativa-de-servicios-multiples-san" target="_blank">partner.fairtradecertified.org.</a> Thanks Matt Pope.</li>
      </ul>
    </div>
    <!--/block__content-->
  </div>
</template>

<script>
export default {
  
}
</script>