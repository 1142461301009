<template>
  <div class="block" id="acknowledgements">
    <div class="block__intro">
      <h2>Acknowledgements</h2>
    </div>

    <div class="block__content">
      <h3 id="items-attributes">Thanks to all these people who helped me to do some great features :)</h3>
      <ul>
        <li><a href="https://github.com/krokodilshnap" target="_blank">Dmitriy Borshchevskiy</a> who did a big part of enableWheelEvent prop</li>
        <li><a href="https://github.com/kaypro4" target="_blank">Matt</a> who made the first pull request and did an excellent job doing the mediaType prop</li>
        <li><a href="https://github.com/elalekey" target="_blank">elalekey</a> who gave me greats ideas to add to the lightbox</li>
        <li>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></li>
      </ul>
    </div>
    <!--/block__content-->
  </div>
</template>

<script>
export default {
  
}
</script>