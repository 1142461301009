<template>
  <div class="block" id="donate">
    <div class="block__intro">
      <h2>Donate</h2>
    </div>

    <div class="block__content">
      <h4 id="items-attributes">I am very happy with the feedback from the community and it makes me want to continue working on this component and create more, so any help is welcome.</h4>
      <ul>
        <li><a href="http://paypal.me/lucaspdonations" target="_blank">Paypal</a></li>
      </ul>
    </div>
    <!--/block__content-->
  </div>
</template>

<script>
export default {
  
}
</script>